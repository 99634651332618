<template functional>
    <span v-if="props.requisite">
        <span :class="{ 'has-text-weight-bold': props.nameBold }">
            {{ $options.methods.formatEmptyString(props.requisite.name) }}
        </span>
        <br>
        <small>
            {{ $options.methods.formatEmptyString(props.requisite.purse) }}
        </small>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
  
  export default {
    name: "RequisiteOutput",
    props: {
      requisite: {
        type: Object,
        default: null
      },
      
      nameBold: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped>

</style>
